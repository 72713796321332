export const isTime = time => {
  const regexp = /^\d{1,2}:\d{1,2}$/

  return regexp.test(time)
}

export const timeToSeconds = time => {
  if(!isTime(time)) {
    return null
  }

  const [hours, minutes] = time.split(':')

  return (Number(hours) * 3600 + Number(minutes) * 60)
}

export const timeDifference = (timeOne, timeTwo, timeType) => {
  if (!isTime(timeOne) || !isTime(timeTwo)) {
    return null
  }

  const difference = timeToSeconds(timeOne) - timeToSeconds(timeTwo)

  if (timeType === 'minutes') {
    return difference / 60
  }

  return difference
}
