<template>
  <rcc-text-input
    v-model="time"
    mask="##:##"
    :label="label"
    :width="width"
    :is-disabled="isDisabled"
    :rules="allRules"
    @blur="onBlur"
  >
    <template v-slot:append>
      <v-menu
        :close-on-content-click="false"
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            width="24"
            height="24"
            icon
            v-bind="attrs"
            v-on="on"
            :disabled="isDisabled"
          >
            <v-icon>mdi-clock-time-eight</v-icon>
          </v-btn>
        </template>

        <v-time-picker
          v-model="time"
          format="24hr"
          :max="max"
          :min="min"
        />
      </v-menu>
    </template>
  </rcc-text-input>
</template>

<script>
import { isTime } from '@/utils/time'

import RccTextInput from './rcc-text-input'

export default {
  name: 'RccTimeInput',

  components: { RccTextInput },

  props: {
    label: {
      type: String,
      default: ''
    },

    width: {
      type: String,
      default: null
    },

    isDisabled: {
      type: Boolean,
      default: false
    },

    value: {
      type: String,
      default: ''
    },

    min: {
      type: String,
      default: ''
    },

    max: {
      type: String,
      default: ''
    },

    rules: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    time: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit('input', value)
      }
    },

    allRules() {
      return [this.timeValidation, ...this.rules]
    }
  },

  methods: {
    timeValidation(value) {
      if (value === null) {
        return true
      }

      if (!isTime(value)) {
        return 'Время должно быть в формате "часы:минуты"'
      }

      const [hours, minutes] = (value || '').split(':')

      if (hours > 23) {
        return 'Нельзя задать больше 23 часов'
      }

      if (minutes > 55) {
        return 'Нельзя задать больше 55 минут'
      }

      return (
        Number(hours) >= 0 &&
        Number(hours) <= 23 &&
        Number(minutes) >= 0 &&
        Number(minutes) <= 59
      )
    },

    onBlur() {
      const [hours, minutes] = (this.value || '').split(':')
      if (!hours && !minutes) {
        return
      }

      if (!hours) {
        this.$emit('input', `00:${minutes}`)
      }

      if (!minutes) {
        this.$emit('input', `${hours}:00`)
      }
    }
  }
}
</script>
